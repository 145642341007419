import { Event } from '@bugsnag/js';
import { isString } from 'lodash';
import { isAxiosError } from './TypesUtiils';
import UniversalLogger from './UniversalLogger';

export const isEventValid = (event: Event): boolean => {
  const error: any = event?.originalError;

  const isWebkitMaskedUrl = event.errors.some((error) => {
    return error.stacktrace.some((frame) => {
      return frame.file?.includes('webkit-masked-url');
    });
  });

  if (isWebkitMaskedUrl) {
    UniversalLogger.debug(
      'Error is from webkit-masked-url. Not reporting to Bugsnag.'
    );
    return false;
  }

  const ignorableMessages = [
    'Network Error',
    'Request aborted',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'timeout of 0ms exceeded',
    `SyntaxError: Unexpected token 'const'`,
    `SyntaxError: Expected token ')'`,
    `SyntaxError: Unexpected token '>'`,
  ];

  let message = '';
  if (isString(error)) {
    message = error;
  } else if (isAxiosError(error) || error instanceof Error) {
    message = error.message ?? '';
  } else {
    return true;
  }

  const includesIgnorableMessage = ignorableMessages.find((ignoreMessage) =>
    message.includes(ignoreMessage)
  );

  return !includesIgnorableMessage;
};
