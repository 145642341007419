import { AxiosError } from 'axios';
import {
  ListingMetadataKeyValue,
  ListingMetadataSection,
} from '../types/real-api-types';

export const isAxiosError = (error: any): error is AxiosError =>
  error.isAxiosError === true;

export const isListingMetadataKeyValue = (
  obj: any
): obj is ListingMetadataKeyValue => {
  return obj.display !== undefined && obj.key !== undefined;
};

export const isListingMetadataSection = (
  obj: any
): obj is ListingMetadataSection => {
  return !isListingMetadataKeyValue(obj);
};
