import { DateTime } from 'luxon';
import Env from './env';

export default class BrowserLogger {
  static log(...messages: any[]): void {
    this.sendLog('log', ...messages);
  }

  static debug(...messages: any[]): void {
    if (Env.NEXT_PUBLIC_DEBUG_FLAG) {
      this.sendLog('log', ...messages);
    }
  }

  static warn(...messages: any[]): void {
    this.sendLog('warn', ...messages);
  }

  static error(...messages: any[]): void {
    this.sendLog('error', ...messages);
  }

  static sendLog(handler: string, ...messages: any[]): void {
    const prependedMessage = `[RC Journey] - ${DateTime.local().toISO()} -`;

    console[handler](prependedMessage, ...messages);
  }
}
