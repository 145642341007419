import React from 'react';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import env from '../utils/env';
import bugsnagConfig from '../utils/bugsnag.json';
import { isAxiosError } from '../utils/TypesUtiils';
import { isEventValid } from '../utils/BugsnagHelper';
import UniversalLogger from '../utils/UniversalLogger';

export default class ErrorService {
  static notify(
    message: string,
    error: Error,
    metadata: { [key: string]: Record<string, unknown> } = {}
  ): void {
    ErrorService.init();

    UniversalLogger.error(message, error);
    Bugsnag.notify(new Error(`${message} - ${error.message}`), (event) => {
      if (isAxiosError(error)) {
        const reportedData = {
          data: JSON.stringify(error.response?.data),
          status: JSON.stringify(error.response?.status),
          statusText: JSON.stringify(error.response?.statusText),
          headers: JSON.stringify(error.response?.headers),
          config: JSON.stringify(error.response?.config),
        };

        event.addMetadata('axios', reportedData);
      }

      Object.keys(metadata).forEach((key) => {
        event.addMetadata(key, metadata[key]);
      });

      event.context = message;
      // Group all the errors with the same message.
      event.groupingHash = message;
    });
  }

  static notifyIgnoreNotFound(
    message: string,
    error: Error,
    metadata: { [key: string]: Record<string, unknown> } = {}
  ): void {
    this.notifyIgnoreStatusCodes([404], message, error, metadata);
  }

  static notifyIgnoreStatusCodes(
    statusCodes: number[],
    message: string,
    error: Error,
    metadata: { [key: string]: Record<string, unknown> } = {}
  ): void {
    if (isAxiosError(error) && statusCodes.includes(error.response?.status)) {
      UniversalLogger.debug(
        '[Bugsnag] - ignoring error with response type',
        error.response?.status
      );

      return;
    }

    this.notify(message, error, metadata);
  }

  static init(): void {
    if (Bugsnag.isStarted()) {
      UniversalLogger.debug(
        'Bugsnag already started. Skipping Bugsnag initialization.'
      );
      return;
    }

    Bugsnag.start({
      apiKey: env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact(React)],
      releaseStage: env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE,
      enabledReleaseStages: ['prod', 'stage', 'play'],
      appVersion: bugsnagConfig.version,
      onError: (event: Event) => {
        UniversalLogger.debug('Bugsnag error to be reported:', event);

        if (isEventValid(event)) {
          UniversalLogger.debug('Bugsnag event is valid. Reporting.');
          return true;
        }

        UniversalLogger.debug('Bugsnag event is not valid. Ignoring.');
        return false;
      },
    });
  }
}
