import { NextPage } from 'next';
import ErrorPage, { ErrorProps } from 'next/error';
import React from 'react';
import ErrorService from '../services/ErrorService';

const Error: NextPage<ErrorProps> = ({ statusCode }) => {
  return <ErrorPage statusCode={statusCode} />;
};

Error.getInitialProps = (ctx) => {
  if (ctx.err) {
    const { asPath, query, err } = ctx;
    ErrorService.notify(
      `Error thrown in React portion - ${err.message}`,
      ctx.err,
      {
        request: {
          url: asPath,
          query: query,
          message: err.message,
        },
      }
    );
  }
  return ErrorPage.getInitialProps(ctx);
};

export default Error;
